/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-08 16:11:08
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-08 16:14:47
 */
import http from "./axios_init.js";

export default {
  /**
   * 获取所有的应用数据
   * @returns
   */
  get_apps() {
    return http.post("/admin/v1/app");
  },
};
